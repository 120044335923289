import { PrepaidWalletTransactionType } from '@ancon/wildcat-types'

export const WalletExpiryWarningDays = 14

export const WalletTransactionTypes = [
  PrepaidWalletTransactionType.Purchased,
  PrepaidWalletTransactionType.Used,
  PrepaidWalletTransactionType.Refunded,
  PrepaidWalletTransactionType.Renewed,
]

export const WalletTopUpURLFlag = 'topup'
